import { useState } from "react";
import { MerchantContext } from "./MerchantContext";

export function MerchantProvider({ children }) {
  const [merchantName, setMerchantName] = useState(null);

  return (
    <>
      <MerchantContext.Provider value={{ merchantName, setMerchantName }}>{children}</MerchantContext.Provider>
    </>
  );
}
